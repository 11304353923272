.gam-sandbox {
  // ensure banner centering
  > div > div > iframe {
    margin: 0 auto;
  }
  // overflow hidden on fixed height
  &--overflow-h {
    overflow-y: hidden;
  }
  // placeholder feature
  &--with-placeholder {
    background-color: var(--sbt-background-root);
    background-position: center center;
    background-repeat: no-repeat;

    > div:not(div:empty) {
      background-color: var(--sbt-background-default);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100% !important;
      width: 100% !important;
    }
  }
}