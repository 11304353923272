@use '~@sbt-web/ui/common/css/variables.scss' as v;

.wrapper {
  background-color: var(--sbt-background-default);
  position: relative;
}

.main {
  min-height: 600px;
  position: relative;
}

@media (min-width: v.$desktop_min_width) {
  .wrapper {
    margin: 0 auto;
    max-width: v.$desktop_min_width;
  }
}
