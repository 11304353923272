@use '~@sbt-web/ui/common/css/variables.scss' as v;

$small_content_break: 460px;
$medium_content_break: 670px;

.top-level-section {
  padding-left: var(--sbt-spacing-md);
  padding-right: var(--sbt-spacing-md);
}

.title-section {
  background-color: var(--sbt-background-root);
  display: flex;
  margin-top: var(--sbt-spacing-lg);
  padding-bottom: var(--sbt-spacing-xl);
  padding-top: var(--sbt-spacing-xl);

  @media (max-width: v.$tablet_max_width) {
    flex-direction: column;
    row-gap: var(--sbt-spacing-md);
    margin-bottom: var(--sbt-spacing-md);
  }

  @media (min-width: v.$desktop_min_width) {
    column-gap: var(--sbt-spacing-md);
    flex-direction: row;
    justify-content: center;
    margin-bottom: var(--sbt-spacing-3xl);
  }
}

.title-text {
  display: flex;
  flex-direction: column;

  @media (max-width: v.$tablet_max_width) {
    row-gap: var(--sbt-spacing-2xs);
  }

  @media (min-width: $medium_content_break) and (max-width: v.$desktop_min_width) {
    margin-left: auto;
    margin-right: auto;
  }
}

.new-search-link {
  margin-top: auto;
}

.content-section {
  margin: 0 auto var(--sbt-spacing-3xl);

  @media (min-width: $medium_content_break) {
    max-width: max-content;
  }
}

.title-image {
  align-self: center;
  height: 150px;
}

.content-title {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: var(--sbt-spacing-sm);

  @media (max-width: $small_content_break) {
    align-items: flex-start;
    flex-direction: column;
    row-gap: var(--sbt-spacing-xs);
  }
}

@media (min-width: $small_content_break) {
  .specific-listing-link {
    margin-left: auto;
  }
}

.card-grid {
  @media (max-width: $small_content_break) {
    & {
      --column-count: 1;
      row-gap: var(--sbt-spacing-sm);
    }
  }

  @media (min-width: $small_content_break) and (max-width: $medium_content_break) {
    & {
      --column-count: 2;
    }
  }

  @media (min-width: $medium_content_break) {
    & {
      --column-count: 3;
    }
  }

  display: grid;
  grid-template-columns: repeat(var(--column-count), min-content);
  grid-auto-rows: auto;
  column-gap: var(--sbt-spacing-sm);
  row-gap: var(--sbt-spacing-lg);
  margin: 0;
}

.card {
  border: var(--sbt-border-default);
  border-radius: var(--sbt-corner-md);
  box-sizing: content-box;
  display: inline-block;
}

.card-image-container {
  position: relative;
}

.card-image {
  border-top-left-radius: var(--sbt-corner-md);
  border-top-right-radius: var(--sbt-corner-md);
  display: block;
  height: 140px;
  max-width: 200px;
  object-fit: contain;
  object-position: bottom;
}

.card-image-count {
  position: absolute;
  left: var(--sbt-spacing-sm);
  bottom: var(--sbt-spacing-sm);

  background-color: var(--sbt-opacity3-rgba);
  border-radius: var(--sbt-corner-md);
  color: white;
  column-gap: var(--sbt-spacing-2xs);
  display: flex;
  padding: var(--sbt-spacing-3xs);
}

.card-content {
  margin: var(--sbt-spacing-xs) var(--sbt-spacing-sm);
}

.item-link {
  color: var(--sbt-primary-text-color);
  overflow-wrap: break-word;
}

.item-location {
  color: var(--sbt-secondary-text-color);
}

.adv-slot{
  margin-top: var(--sbt-spacing-md);
}
