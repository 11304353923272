@use '~@sbt-web/ui/common/css/variables.scss' as v;

.container {
  text-align: center;
  padding: 30px 20px 60px;
}

.image {
  margin: 0 auto;
  width: 35%;
  min-width: 200px;
}

.message :global(p) {
  font-size: 14px;
}

@media only screen and (max-width: v.$mobile_max_width) {
  .title {
    margin-top: var(--sbt-spacing-sm);
  }
}
