@use '~@sbt-web/ui/common/css/variables.scss' as v;

.skeleton {

  @media(min-width: v.$desktop_min_width) {
    display: grid;
    grid-template-columns: 1fr v.$desktop_min_width 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'leftSide main rightSide';

    .main {
      grid-area: main;
    }
    .left-aside {
      grid-area: leftSide;
    }
    .right-aside {
      grid-area: rightSide;
    }
  }
}

.left-aside, .right-aside {
  position: relative;
  @media(max-width: v.$tablet_max_width) {
    display: none;
  }
}

.aside-wrapper {
  position: absolute;
  top: var(--sbt-spacing-xl);
  bottom: 0;
  padding: 0 var(--sbt-spacing-sm);
  .left-aside & {
    right: -100%;
    display: flex;
    justify-content: flex-end;
  }
}

.aside-skyscraper {
  position: sticky;
  width: 100%;
  height: 600px;
  top: 15px; // The same value of the skyscrapers in listing
}

.billboard {
  margin: var(--sbt-spacing-xl) var(--sbt-spacing-2xs) var(--sbt-spacing-sm) var(--sbt-spacing-2xs);
  @media(max-width: v.$tablet_max_width) {
    display: none;
  }
}

